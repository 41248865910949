@font-face {
    font-family: "PorscheNext";
    src: url("./fonts/PorscheNext-Regular.woff") format("woff"),
        url("./fonts/PorscheNext-Regular.woff2") format("woff2");
}

body {
    margin: 0;
    font-family: "PorscheNext", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.title, h1 {
    font-family: "PorscheNext", sans-serif;
}
